import React from 'react'
import Navbar from './Navbar'
import '../styles/style.scss'
import { Link } from 'gatsby'

export default function Layout({ children }) {
  return (
    <div className='layout'>
        <header className='head'>
  <Navbar />
  </header>

        <div className='wrap'>

        { children }

        </div>
        <footer>
          <div className='footer-content row'>

                <div className="col col-12 text-center">

            <a className="link" href="tel:+46704934931">070 - 493 49 31</a> | 
            <a className="link" href="mailto:lamiaautowrap@gmail.com">lamiaautowrap@gmail.com</a><br/>
            Vi finns på <a className="link" href="https://goo.gl/maps/CxeWM3uqfJ9ntVq37" target="_blank">Ringvägen 9, 567 33 Vaggeryd</a><br/>
            </div>
     </div>

        </footer>

    </div>
  )
}
