import React from 'react'
import { Link } from 'gatsby'

export default function Navbar() {
  return (
    <nav>

<Link className="logolink" to="/">
<img className="logo" src="/logo.png"/></Link>
        


    <Link to="/solfilm">Solfilm</Link>
    <Link to="/foliering">Foliering</Link>
    <Link to="/referenser">Referenser</Link>
    <Link to="/kontakta-oss">Kontakta oss</Link>




    </nav>
  )
}
