import React from "react"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
  <Layout>
     Sidan kunde inte hittas.
  </Layout>

  )
}

